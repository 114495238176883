const locale = {
  local: {
    key: "local",
    name: "Saxdor Turkey",
    multi_language: false,
    has_payment: false,
    culture: "de-DE",
    currency: "eur",
    symbol: "€",
    lang: "en",
    api: "http://localhost:8000",
    url: "http://localhost:3000",
  },
  sdturkey: {
    key: "sdturkey",
    name: "Saxdor Turkey",
    multi_language: true,
    has_payment: false,
    culture: "de-DE",
    currency: "eur",
    symbol: "€",
    lang: "en",
    api: "https://api.saxdorturkey.com",
    url: "https://newconfigurator.saxdorturkey.com",
  },
};

let { REACT_APP_LOCALE } = process.env;
if (!REACT_APP_LOCALE) REACT_APP_LOCALE = "local";

const selected_locale = locale[REACT_APP_LOCALE];

export const appConfig = {
  api: selected_locale.api,
  locale: selected_locale,
};
